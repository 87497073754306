import React, { useState } from "react";

const ShieldItem = ({ image, title, description }) => {
  const [rotate, setRotate] = useState(false);

  const handleMouseEnter = () => {
    console.log("ENTER");
    setRotate(!rotate);
  };

  const handleMouseLeave = () => {
    setRotate(!rotate);
  };

  return (
    <div
      className="shields-section-content-item"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      // onClick={() => setRotate(!rotate)}
    >
      {/* <div className={`shield-item ${rotate ? "rotate-right" : "rotate-left"}`}> */}
      <div className={`shield-item ${rotate ? "rotate-right" : "rotate-left"}`}>
        <div className="shield-item-section">
          <img
            src={`/images/${rotate ? "rotate-shield.png" : image}`}
            className="img-fluid img-shield-item"
            alt="Oktacron"
          />
          {!rotate ? <h2>{title}</h2> : description && <h3>{description}</h3>}
        </div>
      </div>
    </div>
  );
};

export default ShieldItem;
