import React, { Fragment, useEffect } from "react";
import HelmetSeo from "../../components/Site/Reusable/HelmetSeo";
import Hero from "../../components/Site/Reusable/Hero";
import SpacingItem from "../../components/Site/Reusable/SpacingItem";

import "../../assets/services.scss";
import CyberInfo from "../../components/Site/Reusable/CyberInfo";
import InfoWhiteBlock from "../../components/Site/Sections/Services/InfoWhiteBlock";
import OurServicesTitle from "../../components/Site/Sections/Services/OurServicesTitle";
import OurServiceListItems from "../../components/Site/Sections/Services/OurServiceListItems";
import { useTranslation } from "react-i18next";
import Resources from "../../components/Site/Reusable/Resources";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchSeoListItems } from "../../actions/Site/global";

const Services = () => {
  const { t } = useTranslation();
  const hashValue = window.location.hash.substring(1);

  const dispatch = useDispatch();
  const { dataPagesListSeo } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(fetchSeoListItems(2));
  }, [dispatch]);

  useEffect(() => {
    if (hashValue) {
      const element = document.getElementById(hashValue);

      if (element) {
        element.scrollIntoView({
          behavior: "smooth", // Dodajte 'smooth' za glatki scroll
        });
      }
    }
  }, [hashValue]);

  return (
    <Fragment>
      <HelmetSeo
        title={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acTitle}
        description={
          dataPagesListSeo.length > 0 && dataPagesListSeo[0].acDescription
        }
        keywords={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acKeywords}
      />
      <Hero title={t("services.section1.text")} img={`services-hero.png`} />
      <SpacingItem />
      <CyberInfo text={t("services.section2.text")} />
      <InfoWhiteBlock t={t} />
      <OurServicesTitle
        title={t("services.title")}
        subtitle={t("services.subtitle")}
      />
      <OurServiceListItems t={t} />
      <Resources t={t} />
    </Fragment>
  );
};

export default Services;
