import {
  createNewClientPath,
  editClientPath,
  getListClientPath,
  removeClientPath,
} from "../../constants/apiRoutes";
import { clientActions } from "../../reducers/Admin/clientActions";
import apiService from "../../utils/configAxios";
import { toast } from "react-toastify";

export const fetchCreateNewClient = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(createNewClientPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/client-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchDeleteClient = (pageId) => {
  return (dispatch) => {
    dispatch(clientActions.actionStart());
    return apiService
      .delete(removeClientPath(pageId))
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        return apiService
          .get(getListClientPath(0))
          .then((response) => {
            dispatch(clientActions.fetchAllPagesList(response.data));
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchEditClient = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(editClientPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/client-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchClientPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(clientActions.actionStart());
    return apiService
      .get(getListClientPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(clientActions.fetchAllPagesList(response.data));
        else dispatch(clientActions.fetchAllPagesDetail(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};
