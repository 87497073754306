import { useCallback, useState } from 'react';

export const useModalToggle = (initial) => {
    const [open, setOpen] = useState(initial);
    const [itemForUpdate, setItemForUpdate] = useState(null);

    const toggleHandler = useCallback(
        (providedValue) => {
            setOpen((value) => (providedValue === false ? providedValue : !value));
            providedValue === false &&
                itemForUpdate !== null &&
                setItemForUpdate(null);
        },
        [itemForUpdate]
    );

    return [
        open,
        toggleHandler,
        itemForUpdate,
        useCallback(
            (providedValue) => {
                setItemForUpdate(providedValue);
                toggleHandler();
            },
            [toggleHandler]
        ),
    ];
};