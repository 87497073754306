import React, { useEffect } from "react";
import HelmetSeo from "../../components/Site/Reusable/HelmetSeo";
import Hero from "../../components/Site/Reusable/Hero";
import SpacingItem from "../../components/Site/Reusable/SpacingItem";
import CyberInfo from "../../components/Site/Reusable/CyberInfo";
import TitleDescription from "../../components/Site/Reusable/TitleDescription";
import FourBlocks from "../../components/Site/Sections/SOC/FourBlocks";
import TwoBlockImageText from "../../components/Site/Sections/Siem/TwoBlockImageText";
import WhyOktacron from "../../components/Site/Sections/Home/WhyOktacron";
import Team from "../../components/Site/Sections/About/Team";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchSeoListItems } from "../../actions/Site/global";

const AboutUs = () => {
  const { t } = useTranslation();
  const hashValue = window.location.hash.substring(1);

  const dispatch = useDispatch();
  const { dataPagesListSeo } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(fetchSeoListItems(5));
  }, [dispatch]);

  useEffect(() => {
    if (hashValue) {
      const element = document.getElementById(hashValue);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth", // Dodajte 'smooth' za glatki scroll
        });
      }
    }
  }, [hashValue]);

  const whyBlock = [
    {
      value: t("about.why-block-2-key"),
      text: t("about.why-block-2-value"),
    },
    {
      value: t("about.why-block-3-key"),
      text: t("about.why-block-3-value"),
    },
    {
      value: t("about.why-block-4-key"),
      text: t("about.why-block-4-value"),
    },
    {
      value: t("about.why-block-1-key"),
      text: t("about.why-block-1-value"),
    },
  ];

  const blocks = [
    {
      title: t("about.box-1-title"),
      description: t("about.box-1-desc"),
    },
    {
      title: t("about.box-2-title"),
      description: t("about.box-2-desc"),
    },
    {
      title: t("about.box-3-title"),
      description: t("about.box-3-desc"),
    },
    {
      title: t("about.box-4-title"),
      description: t("about.box-4-desc"),
    },
  ];

  return (
    <div className="about-holder">
      <HelmetSeo
        title={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acTitle}
        description={
          dataPagesListSeo.length > 0 && dataPagesListSeo[0].acDescription
        }
        keywords={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acKeywords}
      />
      <Hero title={t("about.title")} img={`about-hero.png`} />
      <SpacingItem />
      <CyberInfo text={t("about.desc")} />
      <TwoBlockImageText
        title={t("about.block1-title")}
        image={`about-shield.png`}
        description={t("about.block1-subtitle")}
        button={t("about.block1-button")}
        contrast={true}
        link={`services`}
      />
      <TitleDescription title={t("about.main-title-block")} />
      <FourBlocks items={blocks} />
      <WhyOktacron
        title={t("about.why-block-title")}
        img={`about-big-image.png`}
        button={false}
        items={whyBlock}
      />
      <TitleDescription id={`eksperti`} title={t("about.experts-title")} />
      <Team />
    </div>
  );
};

export default AboutUs;
