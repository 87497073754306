import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSidebarMenu } from "../../../actions/Admin/security";
import NavItem from "./NavItem";

const LeftSidebar = () => {
  const dispatch = useDispatch();

  const { setMenuItemsList, loggedUser } = useSelector(
    (state) => state.loggedUserReducer
  );

  const action = loggedUser?.loggedUser?.roles[0];
  useEffect(() => {
    dispatch(fetchSidebarMenu());
  }, [dispatch]);

  const menuItemsList = setMenuItemsList?.filter((item) =>
    action.includes(item.acModule)
  );

  return (
    <div className="sidebar-main-content">
      <div className="navbar-brand-box">
        <div href="#0" className="logo logo-light">
          <span className="logo-lg">
            <img
              src="/images/developers-logo-white.png"
              alt="Developers.rs"
              className="img-fluid"
            />
          </span>
        </div>
      </div>
      <ul className="navbar-nav" id="navbar-nav">
        {menuItemsList?.map((item, key) => (
          <NavItem item={item} key={key} />
        ))}
      </ul>
    </div>
  );
};

export default LeftSidebar;
