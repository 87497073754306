import React from "react";

import "../../assets/mission-vision.scss";
import ParagraphRenderSpacing from "../../utils/ParagraphRenderSpacing";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchSeoListItems } from "../../actions/Site/global";
import HelmetSeo from "../../components/Site/Reusable/HelmetSeo";

const MissionVision = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dataPagesListSeo } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(fetchSeoListItems(16));
  }, [dispatch]);
  return (
    <div className="mission-vision-content-holder-block">
      <HelmetSeo
        title={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acTitle}
        description={
          dataPagesListSeo.length > 0 && dataPagesListSeo[0].acDescription
        }
        keywords={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acKeywords}
      />
      <div className="mission-vision-holder">
        <div className="mission-vision-item mission-item">
          <div className="mission-vision-item-holder">
            <h1>{t("mission.title")}</h1>
            <p>
              <ParagraphRenderSpacing text={t("mission.text")} />
            </p>
          </div>
        </div>
        <div className="mission-vision-item vision-item">
          <div className="mission-vision-item-holder">
            <h1>{t("vision.title")}</h1>
            <p>
              <ParagraphRenderSpacing text={t("vision.text")} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionVision;
