import React, { useRef } from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

//css
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../../assets/Admin/table-data.scss";

import agGridTranslations from "../../../agGridTranslations.json";

const AggridTable = (props) => {
  const { rowData, columnDefs } = props || {};

  const gridRef = useRef();

  const defaultColDef = {
    sortable: true,
    resizable: true,
    pagination: true,
    floatingFilter: true,
  };

  return (
    <div className="table-grid-compontent-main ag-theme-alpine">
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        paginationPageSize={20}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        rowSelection="none"
        localeText={agGridTranslations}
        pagination={true}
      />
    </div>
  );
};

export default AggridTable;
