import React from "react";
import Button from "../../Reusable/Button";

const OurServiceListItems = ({ t }) => {
  return (
    <div className="service-our-service-item-holder-content">
      <div className="container-fluid">
        <div className="row">
          <div className="service-our-service-item-holder">
            <div className="service-our-service-item" id="cyberGuardian">
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/service-1.png)" }}
              >
                <h1>{t("services.guardian.title")}</h1>
                <p>{t("services.guardian.subtitle")}</p>
              </div>
              <div className="content-our-service-content-holder">
                <p>{t("services.guardian.description")}</p>

                <div className="guardian-holder-content">
                  <div className="guardian-holder-content-left">
                    <ul>
                      <li>{t("services.guardian.li-1")}</li>
                      <li>{t("services.guardian.li-2")}</li>
                      <li>{t("services.guardian.li-3")}</li>
                      <li>{t("services.guardian.li-4")}</li>
                      <li>{t("services.guardian.li-5")}</li>
                      <li>{t("services.guardian.li-6")}</li>
                    </ul>
                  </div>
                  <div className="guardian-holder-content-right">
                    <img
                      src="/images/guardian-image.png"
                      className="img-fluid"
                      alt="Oktacron"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="service-our-service-item" id="penetrationTesting">
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/service-2.png)" }}
              >
                <h1>{t("services.penetration.title")}</h1>
                {t("services.penetration.subtitle")
                  .split("\n")
                  .map((tekst, indeks) => (
                    <p key={indeks}>{tekst}</p>
                  ))}
                <div className="shield-content-holder">
                  <span>Procena</span>
                  <img
                    src="/images/shield1.png"
                    alt="Oktacron"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="content-our-service-content-holder">
                <h2>{t("services.penetration.title2")}:</h2>

                <div className="penetration-block-items-list">
                  <div className="penetration-block-list-item">
                    <div className="penetration-item-image">
                      <img
                        src="/images/penetration-1.png"
                        className="img-fluid"
                        alt="Oktacron"
                      />
                    </div>
                    <div className="penetration-item-title">
                      {t("services.penetration.box-1-title")}
                    </div>
                    <div className="penetration-item-desc">
                      {t("services.penetration.box-1-desc")}
                    </div>
                  </div>
                  <div className="penetration-block-list-item">
                    <div className="penetration-item-image">
                      <img
                        src="/images/penetration-2.png"
                        className="img-fluid"
                        alt="Oktacron"
                      />
                    </div>
                    <div className="penetration-item-title">
                      {t("services.penetration.box-2-title")}
                    </div>
                    <div className="penetration-item-desc">
                      {t("services.penetration.box-2-desc")}
                    </div>
                  </div>
                  <div className="penetration-block-list-item">
                    <div className="penetration-item-image">
                      <img
                        src="/images/penetration-3.png"
                        className="img-fluid"
                        alt="Oktacron"
                      />
                    </div>
                    <div className="penetration-item-title">
                      {t("services.penetration.box-3-title")}
                    </div>
                    <div className="penetration-item-desc">
                      {t("services.penetration.box-3-desc")}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="service-our-service-item" id="riskAssessment">
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/service-3.png)" }}
              >
                <h1>{t("services.risk.title")}</h1>
                <p>{t("services.risk.subtitle")}</p>
                <div className="shield-content-holder">
                  <span>Procena</span>
                  <img
                    src="/images/shield1.png"
                    alt="Oktacron"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="content-our-service-content-holder">
                {t("services.risk.description")
                  .split("\n")
                  .map((tekst, indeks) => (
                    <p key={indeks}>{tekst}</p>
                  ))}
              </div>
            </div>

            <div
              className="service-our-service-item"
              id="protectionOfDatabases"
            >
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/service-4.png)" }}
              >
                <h1>{t("services.database.title")}</h1>
                <p>{t("services.database.subtitle")}</p>
                <div className="shield-content-holder">
                  <span>Zaštita</span>
                  <img
                    src="/images/shield2.png"
                    alt="Oktacron"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="content-our-service-content-holder">
                <p>{t("services.database.description")}</p>

                <Button
                  nameClass={`custom-services-button`}
                  text={t("services.database.button")}
                  link={`ibm-guardium`}
                />
              </div>
            </div>

            <div
              className="service-our-service-item"
              id="vulnerabilityManagement"
            >
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/service-5.png)" }}
              >
                <h1>{t("services.vulnerability.title")}</h1>
                <p>{t("services.vulnerability.subtitle")}</p>
                <div className="shield-content-holder">
                  <span>Zaštita</span>
                  <img
                    src="/images/shield2.png"
                    alt="Oktacron"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="content-our-service-content-holder">
                <p>{t("services.vulnerability.description")}</p>

                <Button
                  nameClass={`custom-services-button`}
                  link={`qualys`}
                  text={t("services.vulnerability.button")}
                />
              </div>
            </div>

            <div className="service-our-service-item" id="soc">
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/service-6.png)" }}
              >
                <h1>{t("services.soc.title")}</h1>
                <p>{t("services.soc.subtitle")}</p>
                <div className="shield-content-holder">
                  <span>Otkrivanje</span>
                  <img
                    src="/images/shield3.png"
                    alt="Oktacron"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="content-our-service-content-holder">
                <div className="content-our-service-content-holder-soc">
                  <div className="content-our-service-content-holder-soc-left">
                    {t("services.soc.description")
                      .split("\n")
                      .map((tekst, indeks) => (
                        <p key={indeks}>{tekst}</p>
                      ))}

                    <Button
                      nameClass={`custom-services-button`}
                      text={`UPOZNAJTE NAŠ SOC`}
                      link={`soc`}
                    />
                  </div>
                  <div className="content-our-service-content-holder-soc-right">
                    <img
                      src="/images/soc-krugovi.png"
                      className="img-fluid"
                      alt="Oktacron"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="service-our-service-item" id="digitalTraps">
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/service-7.png)" }}
              >
                <h1>{t("services.digital.title")}</h1>
                <p>{t("services.digital.subtitle")}</p>
                <div className="shield-content-holder">
                  <span>Odgovor</span>
                  <img
                    src="/images/shield4.png"
                    alt="Oktacron"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="content-our-service-content-holder">
                <p>{t("services.digital.description")}</p>
              </div>
            </div>

            <div
              className="service-our-service-item"
              id="tacticalThreatIntelligence"
            >
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/service-8.png)" }}
              >
                <h1>{t("services.tactical.title")}</h1>
                <p>{t("services.tactical.subtitle")}</p>
                <div className="shield-content-holder">
                  <span>Odgovor</span>
                  <img
                    src="/images/shield4.png"
                    alt="Oktacron"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="content-our-service-content-holder">
                <p>{t("services.tactical.description")}</p>
              </div>
            </div>

            <div className="service-our-service-item" id="incidentManagement">
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/service-9.png)" }}
              >
                <h1>{t("services.incident.title")}</h1>
                <p>{t("services.incident.subtitle")}</p>
                <div className="shield-content-holder">
                  <span>Odgovor</span>
                  <img
                    src="/images/shield4.png"
                    alt="Oktacron"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="content-our-service-content-holder">
                <p>{t("services.incident.description")}</p>

                <Button
                  nameClass={`custom-services-button`}
                  text={t("services.incident.button")}
                  link={`irms`}
                />
              </div>
            </div>

            <div
              className="service-our-service-item"
              id="incidentRecoverySupport"
            >
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/service-10.png)" }}
              >
                <h1>{t("services.recovery.title")}</h1>
                <p>{t("services.recovery.subtitle")}</p>
                <div className="shield-content-holder">
                  <span>Oporavak</span>
                  <img
                    src="/images/shield5.png"
                    alt="Oktacron"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="content-our-service-content-holder">
                <p>{t("services.recovery.description")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServiceListItems;
