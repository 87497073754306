import React from "react";
import Button from "../../Reusable/Button";
import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";

const WhyOktacron = (props) => {
  const {
    title,
    description,
    img,
    button = true,
    items,
    buttonTitle,
    link,
  } = props || {};

  const formattedTitle = String.raw`${title}`;

  const imageStyle = {
    backgroundImage: `url(${require(`../../../../assets/Media/${img}`)})`,
  };

  const [showAnimations, setShowAnimations] = useState(
    Array(items.length).fill(false)
  );

  useEffect(() => {
    const handleScroll = () => {
      const elementPositions = items.map((item, index) => {
        const element = document.querySelector(
          `.list-items-why-content-title:nth-child(${index + 1})`
        );
        if (element) {
          const elementTop = element.getBoundingClientRect().top;
          const screenHeight = window.innerHeight;
          return elementTop < screenHeight;
        }
        return false;
      });

      setShowAnimations(elementPositions);
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [items]);

  return (
    <div className="why-section-holder" style={imageStyle}>
      <div className="container-fluid">
        <div className="row">
          <div className="why-section-holder-content">
            <div className="why-section-content">
              <div className="why-section-content-left">
                <h2>
                  {formattedTitle.split("\n").map((value, index) => (
                    <Fragment key={index}>
                      {value}
                      <br />
                    </Fragment>
                  ))}
                </h2>
                <h3>{description}</h3>
                {button && (
                  <Button
                    nameClass={`custom-security-button custom-button-why`}
                    text={buttonTitle}
                    link={link}
                  />
                )}
              </div>
              <div className="why-section-content-right">
                <div className="list-items-why-content">
                  {items?.map((value, index) => (
                    <div
                      className={`list-items-why-content-title ${
                        showAnimations[index] ? "show-items-animation" : ""
                      }`}
                      key={index}
                    >
                      <span class="block"></span>
                      <div className="list-items-why-content-item" key={index}>
                        <div className="left-side-why-content-item">
                          <div className="why-content-item-block">
                            {value.value}
                          </div>
                        </div>
                        <div className="right-side-why-content-item">
                          <h4>{value.text}</h4>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyOktacron;
