import React from "react";

const ButtonIcon = (props) => {
  const { text, nameClass, img, documentName } = props || {};

  return (
    <div className={`button-holder-content`}>
      {documentName ? (
        <a href={`${documentName}`} target="_blank" rel="noreferrer">
          <button className={`button-item ${nameClass}`}>
            {text}{" "}
            <span>
              {img && (
                <img
                  src={`/images/${img}`}
                  className="img-fluid"
                  alt="Oktacron"
                />
              )}
            </span>
          </button>
        </a>
      ) : (
        <button className={`button-item ${nameClass}`}>
          {text}{" "}
          <span>
            {img && (
              <img
                src={`/images/${img}`}
                className="img-fluid"
                alt="Oktacron"
              />
            )}
          </span>
        </button>
      )}
    </div>
  );
};

export default ButtonIcon;
