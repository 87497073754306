import React from "react";
import HelmetSeo from "../../components/Site/Reusable/HelmetSeo";
import Hero from "../../components/Site/Reusable/Hero";
import SpacingItem from "../../components/Site/Reusable/SpacingItem";
import BlogItems from "./BlogItems";

import "../../assets/blog.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchSeoListItems } from "../../actions/Site/global";
import { useEffect } from "react";

const Blog = () => {
  const dispatch = useDispatch();
  const { dataPagesListSeo } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(fetchSeoListItems(6));
  }, [dispatch]);
  return (
    <div className="expertise-main">
      <HelmetSeo
        title={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acTitle}
        description={
          dataPagesListSeo.length > 0 && dataPagesListSeo[0].acDescription
        }
        keywords={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acKeywords}
      />
      <Hero title={`Blog`} img={`blog-hero.png`} />
      <SpacingItem />
      <BlogItems />
    </div>
  );
};

export default Blog;
