import React from "react";
import { Fragment } from "react";
import Breadcrumb from "../../components/Admin/Reusable/Breadcrumb";
import AggridTable from "../../components/Admin/Reusable/AggridTable";
import ButtonColumn from "../../components/Admin/Reusable/ButtonColumn";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchTeamPagesList, fetchDeleteTeam } from "../../actions/Admin/team";
import { statusRender } from "../../utils/cellRenderTable";
import { baseUrl } from "../../utils";

const TeamList = () => {
  const dispatch = useDispatch();

  const { dataPagesList } = useSelector((state) => state.teamReducer);

  useEffect(() => {
    dispatch(fetchTeamPagesList());
  }, [dispatch]);

  //akcija brisanja iz liste
  const handleSubmitModal = (pageId) => {
    dispatch(fetchDeleteTeam(pageId));
  };

  const imageRenderer = (params) => (
    <img
      src={baseUrl + "images/" + params.data.acImage}
      className="img-fluid image-preview-block-table"
      alt="Oktacron"
    />
  );

  const customCellRenderer = (params) => (
    <ButtonColumn
      editLink={`/adminManager/team-edit/` + params.data.anId}
      deleteItemId={params.data.anId}
      deleteItemTitle={params.data.acName}
      value={params}
      submitActionModal={handleSubmitModal}
    />
  );

  //settings za tabelu
  const columnDefs = [
    { headerName: "ID", field: "anId", filter: true, width: 100 },
    {
      headerName: "Slika",
      field: "acImage",
      width: 100,
      cellRenderer: imageRenderer,
    },
    {
      headerName: "Ime i prezime",
      field: "acName",
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 250,
    },
    {
      headerName: "Status",
      field: "acStatusName",
      filter: "agTextColumnFilter",
      width: 150,
      cellRenderer: statusRender,
    },
    {
      headerName: "Pozicija",
      field: "acPosition",
      width: 250,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Jezik",
      field: "acLanguageName",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Kreirao",
      field: "ins_acUsername",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Izmenio",
      field: "edit_acUsername",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Datum kreiranja",
      field: "adTimeIns",
      filter: "agDateColumnFilter",
    },
    {
      headerName: "Datum izmene",
      field: "adTimeEdit",
      filter: "agDateColumnFilter",
    },
    {
      headerName: "Panel",
      field: "custom",
      cellRenderer: customCellRenderer,
      pinned: "right",
      width: 120,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb title="Pregled tima" type="team-lista" />
      <AggridTable rowData={dataPagesList} columnDefs={columnDefs} />
    </Fragment>
  );
};

export default TeamList;
