import React from "react";
import { baseUrl } from "../../../../utils";
import i18next from "i18next";

import "flickity/css/flickity.css";

import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { fetchTestimonialsListItems } from "../../../../actions/Site/global";
import Flickity from "react-flickity-component";

const OurPartners = ({ settings, t, flickityOptions }) => {
  const dispatch = useDispatch();
  const { dataPagesListTestimonials } = useSelector(
    (state) => state.globalReducer
  );

  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  const [startLang, setStartLang] = useState(0);

  useEffect(() => {
    dispatch(fetchTestimonialsListItems());

    const languageChangeHandler = (newLanguage) => {
      setCurrentLanguage(newLanguage);
    };

    // Pretplatite se na događaj promene jezika
    i18next.on("languageChanged", languageChangeHandler);

    setStartLang(1);

    // Očistite pretplatu kada se komponenta odmontira
    return () => {
      i18next.off("languageChanged", languageChangeHandler);
    };
  }, [dispatch]);

  useEffect(() => {
    if (startLang > 0) dispatch(fetchTestimonialsListItems());
  }, [currentLanguage]);

  return (
    <div className="partners-section-holder">
      <div className="container-fluid">
        <div className="row">
          <div className="partners-section-holder-content">
            <div className="partners-section-content">
              <h2>{t("home.clients.title")}</h2>

              <div className="carouse-partner-content">
                <Flickity
                  className={"carousel"} // default ''
                  elementType={"div"} // default 'div'
                  options={flickityOptions} // takes flickity options {}
                  disableImagesLoaded={false} // default false
                  reloadOnUpdate // default false
                >
                  {dataPagesListTestimonials &&
                    dataPagesListTestimonials?.map((item, key) => (
                      <div className="partner-item" key={key}>
                        <div className="partner-itme-holder">
                          <div className="partner-item-left-side">
                            <div className="partner-item-left-side-holder">
                              <p>{item.acDescription}</p>

                              <h3>{item.acName}</h3>
                              <h4>{item.acPosition}</h4>
                            </div>
                          </div>
                          <div className="partner-item-left-right">
                            <div className="partner-item-image">
                              <img
                                src={baseUrl + "images/" + item.acImage}
                                className="img-fluid"
                                alt={item.acName}
                              />
                            </div>

                            <img
                              src={baseUrl + "images/" + item.acImageBlock}
                              className="img-fluid img-right-side-partners"
                              alt={item.acName}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </Flickity>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurPartners;
