import React from "react";
import HelmetSeo from "../../components/Site/Reusable/HelmetSeo";
import Hero from "../../components/Site/Reusable/Hero";
import SpacingItem from "../../components/Site/Reusable/SpacingItem";
import TitleDescription from "../../components/Site/Reusable/TitleDescription";
import CyberInfo from "../../components/Site/Reusable/CyberInfo";

import "../../assets/siem.scss";
import "../../assets/services.scss";
import RelatedServices from "../../components/Site/Reusable/RelatedServices";
import CatisService from "../../components/Site/Sections/TehnologySolutions/CatisService";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchSeoListItems } from "../../actions/Site/global";

const Catis = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dataPagesListSeo } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(fetchSeoListItems(12));
  }, [dispatch]);

  return (
    <div className="page-holder-main-content">
      <HelmetSeo
        title={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acTitle}
        description={
          dataPagesListSeo.length > 0 && dataPagesListSeo[0].acDescription
        }
        keywords={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acKeywords}
      />
      <Hero title={t("catis.title")} img={`catis-hero.png`} />
      <SpacingItem />
      <CyberInfo text={t("catis.desc")} />
      <TitleDescription
        title={t("catis.title2")}
        description={t("catis.desc2")}
        button={t("catis.btn2")}
      />
      <CatisService t={t} />
      <RelatedServices
        title={t("related.title")}
        text1={t("related.irms.title1")}
        link1={t("related.irms.link1")}
        text2={t("related.irms.title2")}
        link2={t("related.irms.link2")}
        text3={t("related.irms.title3")}
        link3={t("related.irms.link3")}
        buttonTitle={t("related.button")}
      />
    </div>
  );
};

export default Catis;
