import React from "react";

const OurServicesTitle = ({ title, subtitle }) => {
  return (
    <div className="service-our-service-title-holder-content">
      <div className="container-fluid">
        <div className="row">
          <div className="service-our-service-title-holder">
            <h3>{title}</h3>
            <p>{subtitle}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServicesTitle;
