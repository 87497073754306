import React, { useState } from "react";
import TeamItem from "./TeamItem";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchTeamListItems } from "../../../../actions/Site/global";
import i18next from "i18next";
import { useEffect } from "react";

const Team = () => {
  const dispatch = useDispatch();
  const { dataPagesListTeam } = useSelector((state) => state.globalReducer);

  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  const [startLang, setStartLang] = useState(0);

  useEffect(() => {
    dispatch(fetchTeamListItems());

    const languageChangeHandler = (newLanguage) => {
      setCurrentLanguage(newLanguage);
    };

    // Pretplatite se na događaj promene jezika
    i18next.on("languageChanged", languageChangeHandler);

    setStartLang(1);

    // Očistite pretplatu kada se komponenta odmontira
    return () => {
      i18next.off("languageChanged", languageChangeHandler);
    };
  }, [dispatch]);

  useEffect(() => {
    if (startLang > 0) dispatch(fetchTeamListItems());
  }, [currentLanguage]);

  return (
    <div className="team-holder-content-main">
      <div className="container-fluid">
        <div className="row">
          <div className="team-main-body">
            <div className="team-items-list">
              {dataPagesListTeam?.map((value, index) => (
                <TeamItem item={value} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
