import React, { Fragment } from "react";
import HelmetSeo from "../../components/Site/Reusable/HelmetSeo";
import Hero from "../../components/Site/Reusable/Hero";
import SpacingItem from "../../components/Site/Reusable/SpacingItem";
import TitleDescription from "../../components/Site/Reusable/TitleDescription";
import CyberInfo from "../../components/Site/Reusable/CyberInfo";
import TwoBlockImageText from "../../components/Site/Sections/Siem/TwoBlockImageText";
import SpacingBlueItem from "../../components/Site/Reusable/SpacingBlueItem";

import "../../assets/siem.scss";
import "../../assets/services.scss";
import SiemService from "../../components/Site/Sections/Siem/SiemService";
import MicrosoftBlock from "../../components/Site/Sections/Siem/MicrosoftBlock";
import RelatedServices from "../../components/Site/Reusable/RelatedServices";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { fetchSeoListItems } from "../../actions/Site/global";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const Siem = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dataPagesListSeo } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(fetchSeoListItems(11));
  }, [dispatch]);
  return (
    <Fragment>
      <HelmetSeo
        title={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acTitle}
        description={
          dataPagesListSeo.length > 0 && dataPagesListSeo[0].acDescription
        }
        keywords={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acKeywords}
      />
      <Hero title={t("siem.title")} img={`siem-hero.png`} />
      <SpacingItem />
      <CyberInfo text={t("siem.desc")} />
      <TitleDescription
        title={t("siem.title2")}
        description={t("siem.desc2")}
      />
      <TwoBlockImageText
        title={t("siem.title3")}
        image={`siem-block-1.png`}
        description={t("siem.desc3")}
        button={t("siem.button3")}
        link={`soc`}
      />
      <SpacingBlueItem />
      <TitleDescription title={t("siem.title4")} />
      <SiemService t={t} />
      <MicrosoftBlock t={t} />
      <RelatedServices
        title={t("related.title")}
        text1={t("related.siem.title1")}
        link1={t("related.siem.link1")}
        text2={t("related.siem.title2")}
        link2={t("related.siem.link2")}
        text3={t("related.siem.title3")}
        link3={t("related.siem.link3")}
        buttonTitle={t("related.button")}
      />
    </Fragment>
  );
};

export default Siem;
