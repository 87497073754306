import React from "react";
import SolutionsItem from "./SolutionsItem";

const Solutions = ({ t }) => {
  return (
    <div className="solutions-content-holder">
      <div className="container-fluid">
        <div className="row">
          <div className="solutions-content-holder-main-block">
            <SolutionsItem
              img={`teh-1.png`}
              title={t("tehnologies.block-1-title")}
              description={t("tehnologies.block-1-desc")}
              link={`ibm-guardium`}
              buttonTitle={t("tehnologies.block-1-button")}
            />
            <SolutionsItem
              img={`teh-2.png`}
              title={t("tehnologies.block-2-title")}
              description={t("tehnologies.block-2-desc")}
              link={`qualys`}
              buttonTitle={t("tehnologies.block-2-button")}
            />
            <SolutionsItem
              img={`teh-3.png`}
              title={t("tehnologies.block-3-title")}
              description={t("tehnologies.block-3-desc")}
              link={`siem`}
              buttonTitle={t("tehnologies.block-3-button")}
            />
            <SolutionsItem
              img={`teh-4.png`}
              title={t("tehnologies.block-4-title")}
              description={t("tehnologies.block-4-desc")}
              buttonTitle={t("tehnologies.block-4-button")}
            />
            <SolutionsItem
              img={`teh-5.png`}
              title={t("tehnologies.block-5-title")}
              description={t("tehnologies.block-5-desc")}
              link={`safe-viewer`}
              buttonTitle={t("tehnologies.block-5-button")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solutions;
