import React, { useEffect, useState } from "react";
import HelmetSeo from "../../components/Site/Reusable/HelmetSeo";
import Hero from "../../components/Site/Reusable/Hero";
import SpacingItem from "../../components/Site/Reusable/SpacingItem";
import TitleDescription from "../../components/Site/Reusable/TitleDescription";

import "../../assets/expertise.scss";
import CyberInfo from "../../components/Site/Reusable/CyberInfo";
import ExapandList from "../../components/Site/Sections/SOC/ExapandList";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { fetchQaListItems, fetchSeoListItems } from "../../actions/Site/global";
import { useTranslation } from "react-i18next";

const CyberSecurity = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dataPagesListQa, dataPagesListSeo } = useSelector(
    (state) => state.globalReducer
  );

  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  const [startLang, setStartLang] = useState(0);

  useEffect(() => {
    dispatch(fetchSeoListItems(17));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchQaListItems(1));

    const languageChangeHandler = (newLanguage) => {
      setCurrentLanguage(newLanguage);
    };

    // Pretplatite se na događaj promene jezika
    i18next.on("languageChanged", languageChangeHandler);

    setStartLang(1);

    // Očistite pretplatu kada se komponenta odmontira
    return () => {
      i18next.off("languageChanged", languageChangeHandler);
    };
  }, [dispatch]);

  useEffect(() => {
    if (startLang > 0) dispatch(fetchQaListItems(1));
  }, [currentLanguage]);

  return (
    <div className="expertise-main">
      <HelmetSeo
        title={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acTitle}
        description={
          dataPagesListSeo.length > 0 && dataPagesListSeo[0].acDescription
        }
        keywords={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acKeywords}
      />
      <Hero title={t("cyber.title")} img={`cyber-hero.png`} />
      <SpacingItem />
      <CyberInfo text={t("cyber.description")} />
      <TitleDescription title={t("soc.faq-title")} />
      <ExapandList dataList={dataPagesListQa} />
    </div>
  );
};

export default CyberSecurity;
