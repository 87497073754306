import React from "react";
import PartnerItem from "../../components/Site/Sections/PartnerItem";
import HelmetSeo from "../../components/Site/Reusable/HelmetSeo";
import Hero from "../../components/Site/Reusable/Hero";
import SpacingItem from "../../components/Site/Reusable/SpacingItem";
import CyberInfo from "../../components/Site/Reusable/CyberInfo";
import { useTranslation } from "react-i18next";
import TitleDescription from "../../components/Site/Reusable/TitleDescription";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchSeoListItems } from "../../actions/Site/global";

const Partners = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dataPagesListSeo } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(fetchSeoListItems(14));
  }, [dispatch]);

  const partnerList = [
    {
      name: t("partners.partner-1-title"),
      description: t("partners.partner-1-desc"),
      social: "#0",
      image: "ismt_big.png",
    },
    {
      name: t("partners.partner-2-title"),
      description: t("partners.partner-2-desc"),
      social: "https://fiksni-online.rs/login",
      image: "Frame 1527.png",
    },
    {
      name: t("partners.partner-3-title"),
      description: t("partners.partner-3-desc"),
      social: "https://www.ast.co.rs/",
      image: "AST s logo.jpg",
    },
    {
      name: t("partners.partner-4-title"),
      description: t("partners.partner-4-desc"),
      social: "https://www.informatika.com/",
      image: "informatika_logo-blue.svg",
    },
    {
      name: t("partners.partner-5-title"),
      description: t("partners.partner-5-desc"),
      social: "https://www.ibm.com/us-en",
      image: "ibm-logo.png",
    },
    {
      name: t("partners.partner-6-title"),
      description: t("partners.partner-6-desc"),
      social: "https://www.mds.rs/",
      image: "mds-logo.jpg",
    },
  ];

  return (
    <div className="partners-holder">
      <HelmetSeo
        title={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acTitle}
        description={
          dataPagesListSeo.length > 0 && dataPagesListSeo[0].acDescription
        }
        keywords={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acKeywords}
      />
      <Hero
        title={t("partners.title")}
        img={`partners-hero.png`}
        color="#0A0A50"
      />
      <SpacingItem />
      <CyberInfo text={t("partners.desc")} />
      <TitleDescription title={t("partners.title2")} />

      <div className="partner-holder-content-main">
        <div className="container-fluid">
          <div className="row">
            <div className="partner-main-body">
              <div className="partner-items-list">
                {partnerList?.map((value, index) => (
                  <PartnerItem item={value} key={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
