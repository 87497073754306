import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    seoPagesList: [],
    loading: false,
    error: undefined,
};

const seoSlice = createSlice({
    name: 'seo',
    initialState,
    reducers: {
        clearError(state) {
            state.error = undefined;
        },
        actionStart(state) {
            state.loading = true;
            state.error = undefined;
        },
        fetchAllSeoPagesList(state, action) {
            state.seoPagesList = action.payload;
            state.error = undefined;
            state.loading = false;
        },
    }
});

export const seoActions = seoSlice.actions;

export default seoSlice.reducer;