import React from "react";
import ButtonIcon from "./ButtonIcon";
import i18next from "i18next";

const Resources = () => {
  console.log(i18next.language, "i18next.language");
  return (
    <div className="resource-services-holder-content">
      <div className="resource-services-content-center">
        <h3>Resursi</h3>
        <div className="resource-services-list-items">
          <div className="resource-services-item">
            <div className="resource-services-item-title">
              Oktacron IBM Guardium
            </div>
            <ButtonIcon
              documentName={
                i18next.language == "sr"
                  ? `Oktacron IBM Guardium.pdf`
                  : `Oktacron IBM Guardium.pdf`
              }
              nameClass={`custom-resources-button`}
              text={`Download`}
              img={`icon-down.svg`}
            />
          </div>
          <div className="resource-services-item">
            <div className="resource-services-item-title">
              Oktacron portfolio
            </div>
            <ButtonIcon
              documentName={
                i18next.language == "sr"
                  ? `Oktacron portfolio.pdf`
                  : `Oktacron portfolio.pdf`
              }
              nameClass={`custom-resources-button`}
              text={`Download`}
              img={`icon-down.svg`}
            />
          </div>
          <div className="resource-services-item">
            <div className="resource-services-item-title">
              Oktacron Managed Services
            </div>
            <ButtonIcon
              documentName={
                i18next.language == "sr"
                  ? `Oktacron Managed Services.pdf`
                  : `Oktacron Managed Services.pdf`
              }
              nameClass={`custom-resources-button`}
              text={`Download`}
              img={`icon-down.svg`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
