import React from "react";

const SiemService = ({ t }) => {
  return (
    <div className="service-our-service-item-holder-content">
      <div className="container-fluid">
        <div className="row">
          <div className="service-our-service-item-holder">
            <div className="service-our-service-item">
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/siem-res.png)" }}
              >
                <h1>{t("siem.aspen.title")}</h1>
                {t("siem.aspen.desc")
                  .split("\n")
                  .map((tekst, indeks) => (
                    <p key={indeks}>{tekst}</p>
                  ))}
              </div>
              <div className="content-our-service-content-holder">
                <h2>{t("siem.aspen.title2")}</h2>

                <div className="guardian-holder-content">
                  <div className="guardian-holder-content-left">
                    <ul>
                      <li>{t("siem.aspen.li-1")}</li>
                      <li>{t("siem.aspen.li-2")}</li>
                      <li>{t("siem.aspen.li-3")}</li>
                      <li>{t("siem.aspen.li-4")}</li>
                      <li>{t("siem.aspen.li-5")}</li>
                    </ul>
                  </div>
                  <div className="guardian-holder-content-right">
                    <div className="guardian-holder-content-circle">
                      <div className="guardin-holder-content-main-circle">
                        SIEM
                        <div className="guardin-holder-content-circle guardin-holder-content-circle-1">
                          Big data arhitektura
                        </div>
                        <div className="guardin-holder-content-circle guardin-holder-content-circle-2">
                          Digitalna Forenzika
                        </div>
                        <div className="guardin-holder-content-circle guardin-holder-content-circle-3">
                          Vizuelna Rekonstrukcija
                        </div>
                        <div className="guardin-holder-content-circle guardin-holder-content-circle-4">
                          Automatizovan Odgovor Na Incident
                        </div>
                        <div className="guardin-holder-content-circle guardin-holder-content-circle-5">
                          Digitalne Zamke
                        </div>
                      </div>
                    </div>
                    {/* <img src='/images/siem-image.png' className='img-fluid' alt='Oktacron' /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiemService;
