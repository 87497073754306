import React from "react";

const PartnerItem = ({ item, key }) => {
  return (
    <div className="partner-list-item" key={key}>
      <div className="partner-item-holder">
        <div className="partner-item-image">
          <img
            src={`/images/${item.image}`}
            className="img-fluid"
            alt="Oktacron"
          />
        </div>
        <div className="partner-item-name">{item.name}</div>
        <div className="partner-item-description">{item.description}</div>
        <div className="partner-item-line"></div>
        <div className="partner-item-social"></div>
      </div>
    </div>
  );
};

export default PartnerItem;
