import { logout } from "../actions/Admin/security";
import jwtDecode from "jwt-decode";

const checkToken = (phpToken, dispatch, navigate) => {
  if (phpToken) {
    const decodedToken = jwtDecode(phpToken);
    const currentTime = Date.now() / 1000; // Unix timestamp u sekundama

    if (decodedToken.exp < currentTime) {
      // Ako je token istekao, odjavite korisnika
      dispatch(logout(navigate));
    }
  }
};

export default checkToken;
