import React from "react";
import Button from "./Button";

const TextButtonImageBckg = ({ description, img, link, buttonTitle = "" }) => {
  const imageStyle = {
    backgroundImage: `url(${require(`../../../assets/Media/${img}`)})`,
  };

  return (
    <div className="text-button-image-bckg-content" style={imageStyle}>
      <div className="text-button-image-bckg-holder">
        <p>{description}</p>
        <Button
          nameClass={`custom-button-soc`}
          text={buttonTitle}
          link={link}
        />
      </div>
    </div>
  );
};

export default TextButtonImageBckg;
