import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataPagesList: [],
  dataPagesDetail: [],
  dataPagesTranslationList: [],
  dataPagesDetailTranslation: [],
  loading: false,
  error: undefined,
};

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
    actionStart(state) {
      state.loading = true;
      state.error = undefined;
    },
    fetchAllPagesList(state, action) {
      state.dataPagesList = action.payload;
      state.dataPagesDetail = [];
      state.error = undefined;
      state.loading = false;
    },
    fetchAllPagesDetail(state, action) {
      state.dataPagesDetail = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchAllPagesTranslationList(state, action) {
      state.dataPagesTranslationList = action.payload;
      state.dataPagesDetailTranslation = [];
      state.error = undefined;
      state.loading = false;
    },
    fetchAllPagesDetailTranslation(state, action) {
      state.dataPagesDetailTranslation = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchAllBlogCategoriesList(state, action) {
      state.error = undefined;
      state.loading = false;
    },
  },
});

export const blogActions = blogSlice.actions;

export default blogSlice.reducer;
