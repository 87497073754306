import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchContactFormPagesList } from "../../actions/Admin/contactForm";
import AggridTable from "../../components/Admin/Reusable/AggridTable";
import Breadcrumb from "../../components/Admin/Reusable/Breadcrumb";

const ContactFormList = () => {
  const dispatch = useDispatch();

  const { dataPagesList } = useSelector((state) => state.contactFormReducer);

  useEffect(() => {
    dispatch(fetchContactFormPagesList());
  }, [dispatch]);

  //settings za tabelu
  const columnDefs = [
    { headerName: "ID", field: "anId", filter: true, width: 100 },
    {
      headerName: "Ime i prezime",
      field: "acName",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Email",
      field: "acEmail",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Datum",
      field: "adTimeIns",
      filter: "agDateColumnFilter",
    },
    {
      headerName: "Naslov",
      field: "acSubject",
      tooltipField: "acSubject",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Poruka",
      flex: 1,
      field: "acMessage",
      tooltipField: "acMessage",
      filter: "agTextColumnFilter",
    },
  ];
  return (
    <Fragment>
      <Breadcrumb
        title="Pregled kontakata sa form sajta"
        type="contactForm-lista"
      />
      <AggridTable rowData={dataPagesList} columnDefs={columnDefs} />
    </Fragment>
  );
};

export default ContactFormList;
