import React from "react";
import { Fragment } from "react";
import HelmetSeo from "../../components/Site/Reusable/HelmetSeo";
import Hero from "../../components/Site/Reusable/Hero";
import SpacingItem from "../../components/Site/Reusable/SpacingItem";
import ContactForm from "../../components/Site/Sections/ContactForm";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchSeoListItems } from "../../actions/Site/global";
import { useEffect } from "react";

const Contact = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dataPagesListSeo } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(fetchSeoListItems(8));
  }, [dispatch]);
  return (
    <Fragment>
      <HelmetSeo
        title={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acTitle}
        description={
          dataPagesListSeo.length > 0 && dataPagesListSeo[0].acDescription
        }
        keywords={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acKeywords}
      />
      <Hero title={t("contact.title")} img={`contact-hero.png`} />
      <SpacingItem />
      <ContactForm t={t} title={t("contact.subtitle")} />
    </Fragment>
  );
};

export default Contact;
