import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { isValidEmail } from "../../../utils";
import { fetchContactFormSent } from "../../../actions/Site/global";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../Common/Modal/CustomModal";
import { useModalToggle } from "../../../hooks/useModalToggle";

const ContactForm = ({ title, t }) => {
  const titleName = title ? title : t("home.contact.title");
  const formattedTitle = String.raw`${titleName}`;

  const [showModal, toggleModal] = useModalToggle(false);

  const initialState = {
    acName: "",
    acSubject: "",
    acEmail: "",
    acMessage: "",
    acToken: "",
  };
  const initialStateErrors = {
    acName: "",
    acSubject: "",
    acEmail: "",
  };

  const modalCloseHandler = () => {
    toggleModal(false);
  };

  const key = "6LeUftEoAAAAAGV2wD_uDEKaIrwHMZFw7sKDgkC9";

  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState(initialStateErrors);
  const [errorMessage, setErrorMessage] = useState("");
  const recaptchaRef = useRef();

  const [showMessage, setShowMessage] = useState(false);
  const [showMessageError, setShowMessageError] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getToken();
  }, []);

  const getToken = async () => {
    if (recaptchaRef?.current) {
      const token = await recaptchaRef?.current.executeAsync();
      setFormData((prevData) => ({ ...prevData, acToken: token }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validacija
    const newErrors = {};
    if (!formData.acName) {
      newErrors.acName = t("contact.input-required");
    }
    if (!formData.acSubject) {
      newErrors.acSubject = t("contact.input-required");
    }
    if (!formData.acEmail) {
      newErrors.acEmail = t("contact.input-required");
    }
    if (!formData.acMessage) {
      newErrors.acMessage = t("contact.input-required");
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      if (isValid === false) {
        setErrors({ acEmail: t("contact.input-required") });
        setErrorMessage(t("contact.email-error"));
        setShowMessage(false);
        setShowMessageError(true);
        setTimeout(() => {
          setShowMessageError(false);
        }, 3000);
      } else {
        if (formData.acToken)
          dispatch(
            fetchContactFormSent(formData, showMessageContact, navigate)
          );
      }
    } else {
      setErrorMessage(t("contact.message-error"));
      setShowMessage(false);
      setShowMessageError(true);
      setTimeout(() => {
        setShowMessageError(false);
      }, 3000);
    }
  };

  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const handleInputChangeEmail = (event) => {
    const { name, value } = event.target;
    setIsValid(isValidEmail(value));

    setFormData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const showMessageContact = () => {
    // setShowMessage(true);
    toggleModal();
    setShowMessageError(false);

    setTimeout(() => {
      toggleModal();
    }, 60000);

    setFormData({
      acName: "",
      acSubject: "",
      acEmail: "",
      acMessage: "",
    });
    getToken();
    setErrors(initialStateErrors);
  };

  return (
    <div className="contact-us-holder-main">
      <div className="container">
        <div className="row">
          <div className="contact-us-holder-global">
            <h2>
              {formattedTitle.split("\n").map((value, index) => (
                <Fragment key={index}>
                  {value}
                  <br />
                </Fragment>
              ))}
            </h2>
            {t("contact.desc")
              .split("\n")
              .map((tekst, indeks) => (
                <p key={indeks}>{tekst}</p>
              ))}

            <div className="contact-form-info-messages">
              {showMessage && (
                <div className={`text-form-message ${showMessage && "active"}`}>
                  {t("contact.message")}
                </div>
              )}
              {showMessageError && (
                <div
                  className={`text-form-message ${
                    showMessageError && "active"
                  }`}
                >
                  {errorMessage}
                </div>
              )}
            </div>

            <form onSubmit={handleSubmit}>
              <ReCAPTCHA sitekey={key} size="invisible" ref={recaptchaRef} />
              <div className="des-block1">
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <input
                      className={`form-control ${
                        errors.acName && "has-error-input"
                      }`}
                      type="text"
                      name="acName"
                      value={formData.acName}
                      placeholder={t("contact.placeholder-name")}
                      onChange={handleInputChange}
                    />

                    <input
                      className={`form-control ${
                        errors.acEmail && "has-error-input"
                      }`}
                      type="text"
                      name="acEmail"
                      value={formData.acEmail}
                      placeholder={t("contact.placeholder-email")}
                      onChange={handleInputChangeEmail}
                    />
                    <input
                      className={`form-control ${
                        errors.acSubject && "has-error-input"
                      }`}
                      type="text"
                      name="acSubject"
                      placeholder={t("contact.placeholder-subject")}
                      value={formData.acSubject}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-lg-6 col-sm-12">
                    <textarea
                      className={`form-control ${
                        errors.acMessage && "has-error-input"
                      }`}
                      name="acMessage"
                      placeholder={t("contact.placeholder-message")}
                      value={formData.acMessage}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="submit-form-button">
                  <button
                    type="submit"
                    className="custom-contact-button"
                    style={{ border: "none" }}
                  >
                    {t("contact.button")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showModal && (
        <CustomModal
          backdrop={false}
          centered={true}
          footerContent={false}
          modalTitle={t("contact.messageTitle")}
          show={showModal}
          hideModal={modalCloseHandler}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: t("contact.message"),
            }}
          />
        </CustomModal>
      )}
    </div>
  );
};

export default ContactForm;
