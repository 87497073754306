import { Button, Tooltip } from "@mui/material";
import React from "react";
import { Fragment } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";

const ButtonColumnLanguage = (props) => {
  const { value, id } = props || {};
  return (
    <Fragment>
      {value?.map((item, key) => (
        <Link
          to={`/adminManager/edit-blog-detail/${item.anBlogId}/${item.anId}`}
          key={key}
        >
          <Button
            className="edit-button-aggrid edit-button-aggrid-delete-button custom-btn-aggrid-customization button-lanuage-form-list"
            variant="contained"
            color="primary"
            size="small"
            key={key}
            startIcon={<LanguageIcon />}
          >
            {item.acLanguageName}
          </Button>
        </Link>
      ))}

      <Link to={`/adminManager/add-blog-detail/${id}`}>
        <Tooltip title="Kreiranje detalja vesti na različitim jezicima">
          <Button
            className="edit-button-aggrid edit-button-aggrid-delete-button custom-btn-aggrid-customization button-lanuage-form-list"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          ></Button>
        </Tooltip>
      </Link>
    </Fragment>
  );
};

export default ButtonColumnLanguage;
