import React from "react";
import Button from "../../Reusable/Button";

const TwoBlockImageText = (props) => {
  const {
    title,
    description,
    image,
    button,
    contrast = false,
    link,
  } = props || {};

  return (
    <div
      className={`two-block-image-text-holder-content ${
        contrast && "contrast-two-block-image-text"
      }`}
    >
      <div className="two-block-image-text-body">
        <div className="two-block-image-text-left">
          <img src={`/images/${image}`} className="img-fluid" alt="Oktacron" />
        </div>
        <div className="two-block-image-text-right">
          <div className="two-block-image-text-right-body">
            <h2>{title}</h2>
            <p>{description}</p>
            <Button
              text={button}
              nameClass={`custom-buttom-gold`}
              link={link}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoBlockImageText;
