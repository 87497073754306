import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

//ikonice
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

const NavItem = ({ item }) => {
  const [openMenus, setOpenMenus] = useState({});

  const isMenuOpen = (menuKey) => openMenus[menuKey];

  const toggleMenuFunction = (page) => {
    toggleMenu(page);
  };

  const toggleMenu = (menuKey) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [menuKey]: !prevState[menuKey],
    }));
  };

  const menuItemMain = (item) => {
    return (
      <Fragment>
        <FontAwesomeIcon icon={item.acIcon} />
        <span>{item.acName}</span>
        {item.children?.length > 0 &&
          (isMenuOpen(item.acModule) ? (
            <KeyboardArrowDownIcon />
          ) : (
            <KeyboardArrowRightIcon />
          ))}
      </Fragment>
    );
  };

  return (
    <li
      className="nav-item nav-item-main"
      onClick={() => toggleMenuFunction(item.acModule)}
    >
      {item.children?.length > 0 ? (
        <div className="nav-link menu-link nav-link-main">
          {menuItemMain(item)}
        </div>
      ) : (
        <Link
          to={`/adminManager/${item.acPath}`}
          className="nav-link menu-link nav-link-main"
        >
          {menuItemMain(item)}
        </Link>
      )}
      {item.children?.length > 0 && (
        <div
          className={`menu-dropdown collapse ${
            isMenuOpen(item.acModule) ? "show" : ""
          }`}
          id="sidebarDashboards"
        >
          <ul className="nav nav-sm flex-column">
            <li className="nav-item">
              {item.children?.map((item2, key2) => (
                <Link
                  onClick={() => toggleMenuFunction(item.acModule)}
                  to={`/adminManager/${item2.acPath}`}
                  className="nav-link"
                  data-key="t-analytics"
                  key={key2}
                >
                  {item2.acName}
                </Link>
              ))}
            </li>
          </ul>
        </div>
      )}
    </li>
  );
};

export default NavItem;
