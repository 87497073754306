import React from "react";

const QualysService = ({ t }) => {
  return (
    <div className="service-our-service-item-holder-content">
      <div className="container-fluid">
        <div className="row">
          <div className="service-our-service-custom-template-holder">
            <div className="serive-our-custom-template-text-block">
              <h1>{t("qualys.title3")}</h1>
              <p>{t("qualys.desc3")}</p>
            </div>
            <div
              className="serive-our-custom-template-image-block"
              style={{ backgroundImage: "url(/images/qualys-res.png)" }}
            >
              <ul>
                <li>{t("qualys.li-1")}</li>
                <li>{t("qualys.li-2")}</li>
                <li>{t("qualys.li-3")}</li>
                <li>{t("qualys.li-4")}</li>
                <li>{t("qualys.li-5")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QualysService;
