import React, { useState } from "react";
import ExpandListItem from "./ExpandListItem";

const ExapandList = ({ dataList }) => {
  const [active, setActive] = useState(null);

  const handleClickOpenFunction = (index) => {
    setActive((prevActive) => (prevActive === index ? null : index));
  };

  return (
    <div className="expand-list-content-holder">
      <div className="container-fluid">
        <div className="row">
          <div className="expand-list-content">
            {dataList?.map((item, key) => (
              <ExpandListItem
                key={key}
                isActive={active === key}
                handleOpenFunction={() => handleClickOpenFunction(key)}
                title={item.acTitle}
                description={item.acDescription}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExapandList;
