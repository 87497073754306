import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

const CustomModal = ({
  show,
  hideModal,
  modalTitle,
  children,
  actionSubmitModal,
  classValue,
  size,
  backdrop = "static",
  centered = false,
  footerContent = true,
}) => {
  return (
    <Modal
      show={show}
      onHide={hideModal}
      size={size}
      animation={true}
      backdrop={`static`}
      keyboard={false}
      className={classValue}
      centered={centered}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {footerContent && (
        <Modal.Footer>
          {actionSubmitModal && (
            <Button variant="success" onClick={actionSubmitModal}>
              Potvrda
            </Button>
          )}
          <Button variant="danger" onClick={hideModal}>
            Odustani
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CustomModal;
