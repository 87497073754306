export const getUsers = () => `/api/users`;
export const userLogin = () => `/api/login`;
export const userLogout = () => `/api/logged-out`;
export const getMenuItems = () => `/api/menu/items`;
export const sentContactFormPath = () => `/api/contact-form/sent`;

export const getClientsListItems = () => `/api/clients/get/items`;
export const getTestimonialsListItems = (lang) =>
  `/api/testimonials/get/items/${lang}`;
export const getQaListItems = (lang, type) =>
  `/api/qa/get/items/${lang}/${type}`;
export const getTeamListItems = (lang, type) => `/api/team/get/items/${lang}`;
export const getSeoListItems = (page) => `/api/seo-page/get/items/${page}`;
export const getDocumentsListItems = (lang) =>
  `/api/documents/get/items/${lang}`;
export const getCaseStudyListItems = (lang) =>
  `/api/case-study/get/items/${lang}`;
export const getDocumentsAllListItems = (lang) =>
  `/api/documents/get/all/${lang}`;
export const getBlogAllListItems = (lang, id) =>
  `/api/blog/get/all/${lang}/${id}`;

//admin panel

//korisnici
export const addNewUser = () => `/api/admin/add-new-user`;

//seo
export const getSeoPagesList = (pageId) => `/api/admin/seo/pages/${pageId}`;
export const seoPageDelete = (pageId) =>
  `/api/admin/seo/pages/delete/${pageId}`;
export const updateSeoItemPath = () => `/api/admin/seo/update`;

//qa
export const addNewQa = () => `/api/admin/add-new-qa`;
export const changeOrderQaItems = () => `/api/admin/change-order-items`;
export const editQa = () => `/api/admin/edit-qa`;
export const getQaPagesList = (pageId) => `/api/admin/qa/pages/${pageId}`;
export const getQaPagesOrderList = (categoryId) =>
  `/api/admin/qa/pages/order/${categoryId}`;
export const qaItemDelete = (pageId) => `/api/admin/qa/pages/delete/${pageId}`;

//kategorije
export const addNewDocument = () => `/api/admin/add-new-document`;
export const addNewDocumentCategory = () =>
  `/api/admin/add-new-document-category`;
export const editDocumentCategory = () => `/api/admin/edit-document-category`;
export const editDocument = () => `/api/admin/edit-document`;
export const getAllDocumentCategories = () => `/api/admin/documents/categories`;
export const getDocumentPagesList = (pageId) =>
  `/api/admin/document/pages/${pageId}`;
export const documentItemDelete = (pageId) =>
  `/api/admin/document/pages/delete/${pageId}`;
export const documentCategoryItemDelete = (pageId) =>
  `/api/admin/document/categories/delete/${pageId}`;
export const getReadFilePath = (filePath) =>
  `/api/admin/document/read/?path=${encodeURIComponent(filePath)}`;

//klijenti
export const createNewClientPath = () => `/api/admin/add-client`;
export const editClientPath = () => `/api/admin/edit-client`;
export const removeClientPath = (pageId) =>
  `/api/admin/client/delete/${pageId}`;
export const getListClientPath = (pageId) => `/api/admin/client/list/${pageId}`;

//tim
export const createNewTeamPath = () => `/api/admin/add-team`;
export const editTeamPath = () => `/api/admin/edit-team`;
export const removeTeamPath = (pageId) => `/api/admin/team/delete/${pageId}`;
export const getListTeamPath = (pageId) => `/api/admin/team/list/${pageId}`;

//blog
export const createNewBlogPath = () => `/api/admin/add-blog`;
export const createNewDetailBlogPath = () => `/api/admin/add-detail-blog`;
export const editBlogPath = () => `/api/admin/edit-blog`;
export const editBlogTranslationPath = () => `/api/admin/edit-blog-translation`;
export const removeBlogPath = (pageId) => `/api/admin/blog/delete/${pageId}`;
export const removeBlogTranslationPath = (pageId) =>
  `/api/admin/blog/translation/delete/${pageId}`;
export const getListBlogPath = (pageId) => `/api/admin/blog/list/${pageId}`;
export const getListBlogDetailPath = (pageId) =>
  `/api/admin/blog/translation/list/${pageId}`;

//case study
export const createNewCaseStudyPath = () => `/api/admin/add-caseStudy`;
export const editCaseStudyPath = () => `/api/admin/edit-caseStudy`;
export const removeCaseStudyPath = (pageId) =>
  `/api/admin/caseStudy/delete/${pageId}`;
export const getListCaseStudyPath = (pageId) =>
  `/api/admin/caseStudy/list/${pageId}`;
export const getReadFileCaseStudyPath = (filePath) =>
  `/api/admin/document/caseStudy/read/?path=${encodeURIComponent(filePath)}`;

//kontakt forma
export const getListContactFormPath = (pageId) =>
  `/api/admin/contactForm/list/${pageId}`;

//role
export const createNewRolePath = () => `/api/admin/add-role`;
export const editRolePath = () => `/api/admin/edit-role`;
export const removeRolePath = (pageId) => `/api/admin/role/delete/${pageId}`;
export const getListRolePath = (pageId) => `/api/admin/role/list/${pageId}`;

//korisnici
export const createNewUserPath = () => `/api/admin/add-user`;
export const editUserPath = () => `/api/admin/edit-user`;
export const removeUserPath = (pageId) => `/api/admin/user/delete/${pageId}`;
export const getListUserPath = (pageId) => `/api/admin/user/list/${pageId}`;

//rekil su o nama
export const createNewTestimonialsPath = () => `/api/admin/add-testimonials`;
export const editTestimonialsPath = () => `/api/admin/edit-testimonials`;
export const removeTestimonialsPath = (pageId) =>
  `/api/admin/testimonials/delete/${pageId}`;
export const getListTestimonialsPath = (pageId) =>
  `/api/admin/testimonials/list/${pageId}`;
