import React from 'react'

const ImageBlock = ({ img }) => {

    return (
        <div className='image-block-content'>
            <div className='image-block-holder'>
                <img src={`/images/${img}`} className='img-fluid' alt="Oktacron" />
            </div>
        </div>
    )
}

export default ImageBlock