import React from "react";
import { Fragment } from "react";
import SpacingItem from "../../Reusable/SpacingItem";
import { useTranslation } from "react-i18next";
import { TypeAnimation } from "react-type-animation";
import { useEffect } from "react";
import { useState } from "react";

const Hero = () => {
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <Fragment>
      <div className="hero-section-holder des-block">
        <video
          id="background-video"
          autoPlay
          loop
          muted
          poster="/images/hero-image.png"
        >
          <source src="/images/hero-video.mp4" type="video/mp4" />
        </video>
        <div className="container-fluid">
          <div className="row">
            <div className="hero-section-holder-content">
              <div className="hero-section-content">
                <h2>{t("home.hero.subtitle")}</h2>

                <TypeAnimation
                  sequence={[t("home.hero.title"), 1000]}
                  wrapper="h1"
                  speed={0.01} // Postavite na manju vrednost
                  deletionStep={90} // Povećajte vrednost za brisanje
                  key={language}
                />

                <h3>{t("home.hero.description")}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-section-holder mob-block hero-section-holder-mobile">
        <video
          id="background-video"
          autoPlay
          loop
          muted
          poster="/images/hero-image.png"
        >
          <source src="/images/hero-video.mp4" type="video/mp4" />
        </video>
        <div className="hero-section-holder-main-content-item">
          <div className="container-fluid">
            <div className="row">
              <div className="hero-section-holder-content">
                <div className="hero-section-content">
                  <h2>{t("home.hero.subtitle")}</h2>
                  <TypeAnimation
                    sequence={[t("home.hero.title"), 1000]}
                    wrapper="h1"
                    speed={0.01} // Postavite na manju vrednost
                    deletionStep={90} // Povećajte vrednost za brisanje
                    key={language}
                  />
                  <h3>{t("home.hero.description")}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SpacingItem />
    </Fragment>
  );
};

export default Hero;
