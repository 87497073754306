import React, { useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth_context";
import ThemeWrapperAdmin from "../../components/Admin/UI/ThemeWrapperAdmin";
import { useDispatch } from "react-redux";
import checkToken from "../checkToken";

const AdminRoute = ({
  redirectPath = "/adminManager/login",
  component: Component,
  ...otherProps
}) => {
  const phpToken = localStorage.getItem("phpToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    //provera tokena da li je istekao na svako ucitavanje aplikacije
    checkToken(phpToken, dispatch, navigate);
  }, [dispatch, navigate, phpToken]);

  const auth = useContext(AuthContext);
  if (!auth.validToken) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <div className="main-content main-content-admin">
      <ThemeWrapperAdmin>
        <Component {...otherProps} />
      </ThemeWrapperAdmin>
    </div>
  );
};

export default AdminRoute;
