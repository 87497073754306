import React from 'react'
import ExpandListItem from './ExpandListItem';

const ExapandList = ({ expandList }) => {

    return (
        <div className='expertise-content-holder'>
            <div className='expand-list-content-holder'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='expand-list-content'>
                            {expandList?.map((value, index) => (
                                <ExpandListItem key={index} val={value} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExapandList