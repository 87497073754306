import React from "react";
import Button from "../../Reusable/Button";

const InfoBlock = ({ title, desc, buttonTitle }) => {
  return (
    <div className="info-block-holder-content">
      <div className="info-main-content-center">
        <h3>{title}</h3>
        <p>{desc}</p>
        <Button text={buttonTitle} nameClass={`custom-contact-button`} />
      </div>
    </div>
  );
};

export default InfoBlock;
