import React from "react";

const MicrosoftBlock = ({ t }) => {
  return (
    <div className="microsoft-block-holder-content">
      <div className="container-fluid">
        <div className="row">
          <div className="microsoft-block-holder-body">
            <h2>{t("siem.microsoft.title")}</h2>
            <p>{t("siem.microsoft.desc")}</p>
            <ul>
              <li>{t("siem.microsoft.li-1")}</li>
              <li>{t("siem.microsoft.li-2")}</li>
              <li>{t("siem.microsoft.li-3")}</li>
              <li>{t("siem.microsoft.li-4")}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MicrosoftBlock;
