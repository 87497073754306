import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

const Button = (props) => {
  const { text, nameClass, link = "", href = false } = props || {};

  return (
    <Fragment>
      {href === true ? (
        <a href={link} target="_blank" rel="noreferrer">
          <button className={`button-item ${nameClass}`}>{text}</button>
        </a>
      ) : (
        <Link to={"/" + link}>
          <button className={`button-item ${nameClass}`}>{text}</button>
        </Link>
      )}
    </Fragment>
  );
};

export default Button;
