import React, { Fragment, useEffect } from "react";
import ButtonColumn from "../../components/Admin/Reusable/ButtonColumn";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCaseStudyPagesList,
  getFileReadFromCaseStudy,
  fetchDeleteCaseStudy,
} from "../../actions/Admin/caseStudy";
import { statusRender } from "../../utils/cellRenderTable";
import AggridTable from "../../components/Admin/Reusable/AggridTable";
import Breadcrumb from "../../components/Admin/Reusable/Breadcrumb";

const CaseStudyList = () => {
  const dispatch = useDispatch();

  const { dataPagesList } = useSelector((state) => state.caseStudyReducer);

  useEffect(() => {
    dispatch(fetchCaseStudyPagesList());
  }, [dispatch]);

  //akcija brisanja iz liste
  const handleSubmitModal = (pageId) => {
    dispatch(fetchDeleteCaseStudy(pageId));
  };

  const handleFileRead = (caseStudy) => {
    dispatch(getFileReadFromCaseStudy(caseStudy));
  };

  const caseStudyRender = (params) => {
    return (
      <div
        className="link-from-table"
        onClick={() => handleFileRead(params.data.acDocument)}
      >
        {params.value}
      </div>
    );
  };

  const customCellRenderer = (params) => (
    <ButtonColumn
      editLink={`/adminManager/case-study-edit/` + params.data.anId}
      deleteItemId={params.data.anId}
      deleteItemTitle={params.data.acTitle}
      value={params}
      submitActionModal={handleSubmitModal}
    />
  );

  //settings za tabelu
  const columnDefs = [
    { headerName: "ID", field: "anId", filter: true, width: 100 },
    {
      headerName: "Naslov",
      field: "acTitle",
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 250,
    },
    {
      headerName: "Dokument",
      field: "acOrigianlName",
      tooltipField: "acOrigianlName",
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 250,
      cellRenderer: caseStudyRender,
    },
    {
      headerName: "Status",
      field: "acStatusName",
      filter: "agTextColumnFilter",
      cellRenderer: statusRender,
      width: 150,
    },
    {
      headerName: "Jezik",
      field: "acLanguageName",
      filter: "agTextColumnFilter",
      width: 150,
    },
    {
      headerName: "Kreirao",
      field: "ins_acUsername",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Izmenio",
      field: "edit_acUsername",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Datum kreiranja",
      field: "adTimeIns",
      filter: "agDateColumnFilter",
    },
    {
      headerName: "Datum izmene",
      field: "adTimeEdit",
      filter: "agDateColumnFilter",
    },
    {
      headerName: "Panel",
      field: "custom",
      cellRenderer: customCellRenderer,
      pinned: "right",
      width: 120,
    },
  ];
  return (
    <Fragment>
      <Breadcrumb title="Pregled case study" type="caseStudy-lista" />
      <AggridTable rowData={dataPagesList} columnDefs={columnDefs} />
    </Fragment>
  );
};

export default CaseStudyList;
