import React from "react";
import Button from "../../Reusable/Button";

const Soc = ({ t, link }) => {
  return (
    <div className="soc-section-holder">
      <div className="container-fluid">
        <div className="row">
          <div className="soc-section-holder-content">
            <div className="soc-section-content">
              <div className="soc-bckg-image"></div>
              <div className="soc-section-content-left">
                <img
                  src="/images/soc-1.png"
                  className="img-fluid soc-image-left"
                  alt="Oktacron"
                />
                <div className="mob-block">
                  <Button
                    nameClass={`custom-button-gold2`}
                    text={`Saznajte više`}
                    link={link}
                  />
                </div>
              </div>
              <div className="soc-section-content-right">
                <h2 className="des-block">{t("home.soc.title")}</h2>
                <h2 className="mob-block">{t("home.soc.title")}</h2>
                <h3>{t("home.soc.description")}</h3>
                <div className="des-block">
                  <Button
                    nameClass={`custom-button-gold2`}
                    text={t("home.soc.button")}
                    link={link}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Soc;
