import React, { Fragment } from "react";
import HelmetSeo from "../../components/Site/Reusable/HelmetSeo";

import "../../assets/blog.scss";
import BlogItems from "./BlogItems";
import { fetchBlogListItems } from "../../actions/Site/global";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import i18next from "i18next";
import { useEffect } from "react";
import { useState } from "react";
import { baseUrl, formatDate } from "../../utils";
import Hero from "../../components/Site/Reusable/Hero";
import SpacingItem from "../../components/Site/Reusable/SpacingItem";

const BlogDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { dataPagesListBlogAll } = useSelector((state) => state.globalReducer);

  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  const [startLang, setStartLang] = useState(0);

  useEffect(() => {
    dispatch(fetchBlogListItems(id));

    const languageChangeHandler = (newLanguage) => {
      setCurrentLanguage(newLanguage);
    };

    // Pretplatite se na događaj promene jezika
    i18next.on("languageChanged", languageChangeHandler);

    setStartLang(1);

    // Očistite pretplatu kada se komponenta odmontira
    return () => {
      i18next.off("languageChanged", languageChangeHandler);
    };
  }, [dispatch]);

  useEffect(() => {
    if (startLang > 0) {
      dispatch(fetchBlogListItems(id));
    }
  }, [currentLanguage]);

  return (
    <div className="blog-detail-main">
      {dataPagesListBlogAll[0] ? (
        <Fragment>
          <HelmetSeo
            title={`${dataPagesListBlogAll[0]?.acTitle} | Oktacron`}
            description={dataPagesListBlogAll[0]?.acSubtitle}
            keywords={dataPagesListBlogAll[0]?.acTags}
            ogType="article"
            ogTitle={dataPagesListBlogAll[0]?.acTitle}
            ogDescription={dataPagesListBlogAll[0]?.acSubtitle}
            ogImage={baseUrl + "images/" + dataPagesListBlogAll[0]?.acImage}
            ogUrl={`https://www.oktacron.rs/blog-detaljno/${dataPagesListBlogAll[0]?.acUrl}/${id}`}
            ogsiteName="Oktacron"
            ogImageWidth="1200"
            ogImageHeight="800"
          />
          <div className="container">
            <div className="row">
              <div className="blog-detail-container">
                <img
                  src={baseUrl + "images/" + dataPagesListBlogAll[0]?.acImage}
                  className="img-fluid"
                  alt="GoldDigger Disguises as Fake Android App To Steal Banking"
                />

                {dataPagesListBlogAll && (
                  <div>
                    <h1>{dataPagesListBlogAll[0]?.acTitle}</h1>
                    <div className="time-date-blog">
                      {formatDate(dataPagesListBlogAll[0]?.adTimePublish)}
                    </div>
                    <h3 className="subtitle-blog-detail-item">
                      {dataPagesListBlogAll[0]?.acSubtitle}
                    </h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: dataPagesListBlogAll[0]?.acDescription,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <HelmetSeo
            title={`Oktacron`}
            description="Vest koju tražite ne postoji"
          />
          <Hero title={`Blog`} img={`blog-hero.png`} />
          <SpacingItem />
          <div className="container">
            <div className="row">
              <div className="blog-detail-container">
                <div>
                  <h2>Blog koji tražite ne postoji</h2>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
      {/* TODO: postaviti limit 4*/}
      {/* <BlogItems /> */}
    </div>
  );
};

export default BlogDetail;
