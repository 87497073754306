import React from 'react'
import { Fragment } from 'react'

const ErrorPage = () => {
    return (
        <Fragment>
            <div className='hero-section-holder des-block hero-error-page'>
                <video id="background-video" autoPlay loop muted poster="/images/hero-image.png">
                    <source src="/images/hero-video.mp4" type="video/mp4" />
                </video>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='hero-section-holder-content'>
                            <div className='hero-section-content'>
                                <h1>404 Greška</h1>
                                <h3>Stranica koju tražite ne postoji.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='hero-section-holder mob-block hero-section-holder-mobile'>
                <video id="background-video" autoPlay loop muted poster="/images/hero-image.png">
                    <source src="/images/hero-video.mp4" type="video/mp4" />
                </video>
                <div className='hero-section-holder-main-content-item'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='hero-section-holder-content'>
                                <div className='hero-section-content'>
                                    <h1>404 Greška</h1>
                                    <h3>Stranica koju tražite ne postoji.</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ErrorPage