import React from "react";
import ButtonIcon from "../../Reusable/ButtonIcon";
import { baseUrl } from "../../../../utils";

const ExpandListItem = ({ val }) => {
  return (
    <div className={`expand-list-item`}>
      <div className="expand-list-item-header">
        <h4>{val.acName}</h4>
        {val.acDocument != null && (
          <ButtonIcon
            nameClass={`custom-resources-button`}
            text={`Preview`}
            img={`icon-down.svg`}
            documentName={baseUrl + "uploads/documents/" + val.acDocument}
          />
        )}
      </div>
    </div>
  );
};

export default ExpandListItem;
